import { PAYMENT_METHOD_FIELDS } from '~/enums'
import FormField, { IFormField } from '~/factories/FormField'

interface IPaymentMethodTransactionCost {
  value: number
  currency: string
}

interface ITotalPrice {
  value: number
  currency: string
}

export interface IIssuer {
  id: string
  label: string
  value: string
}

export interface ITopupPaymentMethod {
  id: string
  name: string
  label: string
  nameSpace: string
  transactionCost: IPaymentMethodTransactionCost
  image: string
  issuers?: IIssuer[]
  disabled?: boolean
  paymentInformationFields?: PAYMENT_METHOD_FIELDS[]
  paymentInformationFieldsRequired?: string
  totalPrice: ITotalPrice
  geolocationCountryID?: number
  shouldSeparateStreetHouseInputs?: boolean
}

export interface ICheckoutPaymentMethod {
  id: string
  name: string
  transactionCost: IPaymentMethodTransactionCost
  totalPrice: ITotalPrice
  issuers?: IIssuer[]
  disabled?: boolean
  image: string
  paymentInformationFields?: Array<IFormField>
  paymentInformationFieldsRequired?: string
  geolocationCountryID?: number
  shouldSeparateStreetHouseInputs?: boolean
}

export class CheckoutPaymentMethodModel {
  protected readonly id: string
  protected readonly name: string
  protected readonly transactionCost: IPaymentMethodTransactionCost
  protected readonly issuers?: IIssuer[]
  protected readonly disabled?: boolean
  protected readonly image: string
  protected readonly paymentInformationFields: Array<IFormField>
  protected readonly paymentInformationFieldsRequired?: string
  protected readonly totalPrice: ITotalPrice
  protected readonly geolocationCountryID: number
  protected readonly shouldSeparateStreetHouseInputs: boolean

  constructor({
    id,
    name,
    label: topupName,
    issuers,
    disabled,
    transactionCost,
    image,
    paymentInformationFields,
    paymentInformationFieldsRequired,
    totalPrice,
    shouldSeparateStreetHouseInputs,
  }: ITopupPaymentMethod) {
    this.id = id
    // can either be a contentful name or topup label as fallback
    this.name = name || topupName
    this.issuers =
      issuers && issuers.length
        ? issuers.map(issuer => ({ ...issuer, value: issuer.id.toLowerCase() }))
        : []
    this.disabled = disabled
    this.totalPrice = totalPrice
    this.transactionCost = transactionCost

    this.image = image

    this.shouldSeparateStreetHouseInputs =
      typeof shouldSeparateStreetHouseInputs === 'boolean'
        ? shouldSeparateStreetHouseInputs
        : false

    const extendedPaymentInformationFields = [
      ...(paymentInformationFields || []),
    ]

    if (extendedPaymentInformationFields.length) {
      const billingCountryIndex = (paymentInformationFields || []).indexOf(
        PAYMENT_METHOD_FIELDS.BILLING_COUNTRY
      )

      extendedPaymentInformationFields.splice(
        billingCountryIndex > 0
          ? billingCountryIndex + 1
          : (paymentInformationFields || []).length,
        0,
        PAYMENT_METHOD_FIELDS.BILLING_STATE
      )
    }

    this.paymentInformationFields =
      !paymentInformationFields || !paymentInformationFields.length
        ? []
        : (extendedPaymentInformationFields
            .map(field =>
              FormField.create(field, this.shouldSeparateStreetHouseInputs)
            )
            .filter(field => field) as IFormField[])
    this.paymentInformationFieldsRequired = paymentInformationFieldsRequired
  }

  toJSON(): ICheckoutPaymentMethod {
    return {
      id: this.id,
      name: this.name,
      issuers: this.issuers,
      disabled: this.disabled,
      transactionCost: this.transactionCost,
      image: this.image,
      paymentInformationFields: this.paymentInformationFields,
      paymentInformationFieldsRequired: this.paymentInformationFieldsRequired,
      totalPrice: this.totalPrice,
    }
  }
}
