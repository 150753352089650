import { Context, Middleware } from '@nuxt/types'

const TWO_POINT_O_PATHS = [
  '/en/at/paysafecard',
  '/en/at/abon',
  '/en/at/transcash',
  '/en/ca/chatr-mobile',
  '/en/ca/koodo-mobile',
  '/en/ca/lucky-mobile',
  '/en/ca/paysafecard',
  '/en/ca/public-mobile',
  '/en/ca/virgin-mobile',
  '/en/gb/ee-mobile',
  '/en/gb/o2-mobile',
  '/en/gb/paysafecard',
  '/en/gb/playstation-store',
  '/en/gb/steam',
  '/en/gb/tesco-mobile',
  '/en/gb/transcash',
  '/en/ie/eir-mobile',
  '/en/ie/lycamobile',
  '/en/ie/paysafecard',
  '/en/ie/rewarble-perfect-money',
  '/en/ie/three',
  '/en/ie/transcash',
  '/en/mx/telcel',
  '/en/nz/paysafecard',
  '/en-gb/ca/chatr-mobile',
  '/en-gb/ca/koodo-mobile',
  '/en-gb/ca/lucky-mobile',
  '/en-gb/ca/paysafecard',
  '/en-gb/ca/public-mobile',
  '/en-gb/ca/virgin-mobile',
  '/en-gb/gb/ee-mobile',
  '/en-gb/gb/o2-mobile',
  '/en-gb/gb/paysafecard',
  '/en-gb/gb/playstation-store',
  '/en-gb/gb/steam',
  '/en-gb/gb/tesco-mobile',
  '/en-gb/gb/transcash',
  '/en-gb/ie/eir-mobile',
  '/en-gb/ie/lycamobile',
  '/en-gb/ie/paysafecard',
  '/en-gb/ie/rewarble-perfect-money',
  '/en-gb/ie/three',
  '/en-gb/ie/transcash',
  '/en-gb/mx/telcel',
  '/en-gb/nz/paysafecard',
  '/en-gb/at/paysafecard',
  '/en-gb/at/abon',
  '/en-gb/at/transcash',
  '/de/at/paysafecard',
  '/de/at/abon',
  '/de/at/transcash',
]

const routeToTwoPointO: Middleware = ({ next, route }: Context) => {
  if (process.server) return

  if (TWO_POINT_O_PATHS.includes(route.path)) {
    window.location.href = route.path
    next?.(false)
  }
}

export default routeToTwoPointO
