
import { PropOptions } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { UiInput } from '~/components/atoms'
import { UiSelector } from '~/components/molecules'

import { ICountryModel } from '~/models/components/CountrySelector'

const CountriesWithStateSelector = ['US', 'CA']

export default {
  name: 'StateSelector',
  components: {
    UiInput,
    UiSelector,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    isSucceeded: Boolean,
    isDirty: Boolean,
    informationMessage: {
      type: String,
      default: '',
    },
    selectedCountry: {
      type: Object,
      default: undefined,
    } as PropOptions<ICountryModel | undefined>,
    dataTest: {
      type: String,
      default: '',
    },
    showDropdownLabel: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },
  computed: {
    ...mapGetters('stateSelector', ['states']),
    isTextInput() {
      return (
        this.selectedCountry === undefined ||
        !CountriesWithStateSelector.includes(this.selectedCountry.iso)
      )
    },
    isMobile() {
      return this.$mq === 'sm'
    },
    options() {
      this.getStates(this.selectedCountry.iso)
      return this.states.map(state => ({
        value: state.iso,
        label: state.name,
      }))
    },
  },
  watch: {
    selectedCountry(newVal) {
      this.getStates(newVal.iso)
    },
  },
  methods: {
    ...mapActions('stateSelector', ['getStates']),
    handleSimpleInput(e: Event) {
      this.$emit('input', e)
    },
    onPostCodeFocus(): void {
      this.postCodeFocused = true
      this.incorrectPostCode = false
    },
    handleItemSelect(value: string): void {
      this.$emit('input', value)
    },
    onFocus(): void {
      this.$emit('focus')
    },
    onBlur(): void {
      this.$emit('blur')
    },
  },
}
